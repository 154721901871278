import React from 'react'
import * as footer from './footer.module.scss'
const Footer = () => {
    return (
        <footer>
            <div className={footer.copyright}> copyright </div>
        </footer>
    )
}
export default Footer
